<template>
    <div class="grid">  
		<div class="col-12"> 
			<div class="card"> 
                <Toolbar class="pl-0 pr-0">
                    <template v-slot:start> 
                        <h3>Manage {{module_name}}</h3>  
                    </template>
					<template v-slot:end> 
                        <router-link v-if="permissions[8]" to="/companies/manage-branches">
                            <Button v-tooltip.top="'Manage Branches'" label="Manage Branches" icon="pi pi-arrow-up-right" class="p-button-raised p-button-md p-button-warning mr-2" />
                        </router-link>
                    </template> 
				</Toolbar> 
                
                <Toolbar class="pl-0 pr-0">
					<template v-slot:start> 
                        <Button v-if="checkPermission(moduleId,'insert')" v-tooltip.top="'Add New'" :label="showBtnLabels ? 'Add New' : ''" @click="loadForm" icon="pi pi-plus" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-primary mr-2" /> 
                        <Button v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Refresh'" :label="showBtnLabels ? 'Refresh' : ''" icon="pi pi-refresh" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-success mr-2"   @click="getRecords" /> 
                        <Button v-if="checkPermission(moduleId,'export')" v-tooltip.top="'Export'" icon="pi pi-external-link" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-warning mr-2" :label="showBtnLabels ? 'Export' : ''" @click="exportCSV($event)" />
                        <Button v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Remove Filters'" type="button" icon="pi pi-filter-slash" :label="showBtnLabels ? 'Remove Filters' : ''" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-info mr-2"  @click="clearFilter()"/> 
                        <Button v-if="checkPermission(moduleId,'delete')" v-tooltip.top="'Delete Selected'" :label="showBtnLabels ? 'Delete Selected' : ''" icon="pi pi-trash" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-danger mr-2"  @click="deleteRecord" :disabled="!selectedRecords || !selectedRecords.length" />
					</template> 
                    <template v-slot:end> 
                        <Button  v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Helping Material'" :label="showBtnLabels ? 'Help' : ''" @click="showHelp = true" icon="pi pi-question" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-info mr-2"  /> 
                    </template> 
				</Toolbar> 
				<DataTable :resizableColumns="true" columnResizeMode="fit" ref="dt" :value="records" :paginator="true" sortMode="multiple" class="p-datatable-gridlines p-datatable-sm"  :rows="10" dataKey="id" :rowHover="true" 
					v-model:filters="filters" filterDisplay="menu" :loading="loading" :filters="filters" responsiveLayout="scroll"
					:globalFilterFields="['name','sender_email_name','sender_email_address','phone','address','company','description','added_by','modified_by','date_added','status']" :rowsPerPageOptions="[10,20,50,100]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" v-model:selection="selectedRecords" selectionMode="multiple"
                    :scrollable="true" scrollHeight="400px">
                    
					<template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">  
                            <span class="p-input-icon-left mb-2">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Keyword Search" style="width: 100%"/>
                            </span>
                            <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header" @update:modelValue="onToggle"
                                placeholder="Select Columns" style="width: 20em"/>
                        </div>
                    </template>
                    
                    <template #empty> 
                        <div class="table-message"> 
                            <img class="table-message-img" src="layout/images/no-record-found.png" alt="No record found" >
                        </div>
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template> 
                    
                    <Column selectionMode="multiple" style="max-width:3rem;flex-grow:1; flex-basis:160px"></Column>
                    <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header" :exportable="col.field == 'action' ? false:true" :sortable="col.field == 'action' ? false:true" :key="col.field + '_' + index" :class="col.field == 'action' ? 'btnAction':''" :style="col.field !== 'action' ? 'min-width:12rem;flex-grow:1; flex-basis:160px;':'max-width:5rem;flex-grow:1; flex-basis:160px;'"> 
                        <template  #body="{data}">
                            <img  v-if="col.field == 'company_name' && data.logo" :alt="data.name" :src="filesUrl+'companies/'+data.logo" class="tableProfileImg mr-2" />
                            <img  v-if="col.field == 'company_name' && !data.logo" :alt="data.name" :src="filesUrl+'defaults/default-logo.png'" class="tableProfileImg mr-2" />
                            <span v-if="col.field == 'company_name'">{{data.company_name}}</span>

                            <img  v-if="col.field == 'name' && data.image" :alt="data.name" :src="filesUrl+'suppliers/'+data.image" class="tableProfileImg mr-2" />
                            <img  v-if="col.field == 'name' && !data.image" :alt="data.name" :src="filesUrl+'defaults/default.png'" class="tableProfileImg mr-2" />
                            <span v-if="col.field == 'name'">{{data.name}}</span>
                            <span v-if="col.field == 'registered_company_name'">{{data.registered_company_name}}</span>
                            <span v-if="col.field == 'facebook'">{{data.facebook}}</span> 
                            <span v-if="col.field == 'website'">{{data.website}}</span> 
                            <span v-if="col.field == 'skype'">{{data.skype}}</span> 
                            <span v-if="col.field == 'email'">{{data.email}}</span> 
                            <span v-if="col.field == 'phone'">{{data.phone}}</span> 
                            <span v-if="col.field == 'category_name'">{{data.category_name}}</span>    
                            <span v-if="col.field == 'address'">{{data.address}}</span> 
                            <span v-if="col.field == 'country_name'">{{data.country_name}}</span> 
                            <span v-if="col.field == 'state_name'">{{data.state_name}}</span> 
                            <span v-if="col.field == 'city_name'">{{data.city_name}}</span>  
                            <span v-if="col.field == 'zipcode'">{{data.zipcode}}</span>  
                            <span v-else-if="col.field == 'bank_details'">{{shortStr(data.bank_details,0,250)}}</span>
                            <span v-else-if="col.field == 'description'">{{shortStr(data.description,0,50)}}</span>
                            <span v-else-if="col.field == 'date_added'">{{formatDateTime(data.date_added)}}</span>
                            <span v-else-if="col.field == 'added_by'">
                                <span v-if="data.added_by">{{ data.added_by }}</span>
                                <span v-else>System</span>
                            </span>
                            <span v-else-if="col.field == 'modified_by'">{{ data.modified_by}}</span>
                            <span v-else-if="col.field == 'date_modification'">{{formatDateTime(data.date_modification)}}</span>
                            <span v-else-if="col.field == 'status'"> 
                                <Tag :class="data.status == 'Active' ? 'mr-2 status-Active' : 'mr-2 status-Blocked'" icon="pi pi-check" :severity="data.status == 'Active' ? 'success' : 'danger'" :value="data.status"></Tag>
                            </span>
                            <span v-else-if="col.field == 'action'">
                                <div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
                                    <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text" :recordId="data.id" @click="toggleRecordMenu"></Button>
                                    <Menu ref="menu" :popup="true" :model="actions" ></Menu>
                                </div> 
                            </span>
                        </template>
                        <template #filter="{filterModel}">
                            <span v-if="col.field == 'name'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                            </span> 
                            <span v-if="col.field == 'facebook'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by facebook"/>
                            </span> 
                            <span v-if="col.field == 'skype'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by skype"/>
                            </span> 
                            <span v-if="col.field == 'website'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by website"/>
                            </span> 
                            <span v-if="col.field == 'category_name'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by category name"/>
                            </span> 
                            <span v-if="col.field == 'registered_company_name'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by registered company name"/>
                            </span> 
                            <span v-if="col.field == 'email'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by email"/>
                            </span>
                            <span v-if="col.field == 'phone'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by phone"/>
                            </span> 
                            <span v-if="col.field == 'bank_details'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by bank details"/>
                            </span> 
                            <span v-if="col.field == 'address'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by address"/>
                            </span> 
                            <span v-if="col.field == 'company_name'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by company name"/>
                            </span> 
                            <span v-if="col.field == 'country_name'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by country name"/>
                            </span> 
                            <span v-if="col.field == 'state_name'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by state name"/>
                            </span> 
                            <span v-if="col.field == 'city_name'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by city name"/>
                            </span>  
                            <span v-if="col.field == 'zipcode'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by zipcode"/>
                            </span> 
                            <span v-else-if="col.field == 'description'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by about"/>
                            </span> 
                            <span v-if="col.field == 'added_by'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                            </span> 
                            <span v-if="col.field == 'modified_by'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                            </span> 
                            <span v-else-if="col.field == 'date_added'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Date Added"/>
                            </span>
                            <span v-else-if="col.field == 'date_modification'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Date Modification"/>
                            </span> 
                            <span v-else-if="col.field == 'status'">
                                <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any" class="p-column-filter" :showClear="true">
                                    <template #value="slotProps">
                                        <span :class="'customer-badge status-' + slotProps.value" v-if="slotProps.value">{{slotProps.value}}</span>
                                        <span v-else>{{slotProps.placeholder}}</span>
                                    </template>
                                    <template #option="slotProps">
                                        <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                                    </template>
                                </Dropdown>
                            </span>  
                        </template>   
                    </Column>   
				</DataTable>
			</div>
		</div>
        <Toast />
        <ConfirmDialog group="dialog" />
        <!-- form loading -->
        <SupplierForm v-on:formClose="closeForm" v-if="showForm  && (checkPermission(moduleId,'insert') || checkPermission(moduleId,'update'))" :recordId="selectedRecordId" :showForm="true" /> 
        <ModuleHelp v-on:closeHelp="showHelp =false" v-if="showHelp  && checkPermission(moduleId,'view')" :moduleId="moduleId"/>
        
    </div>
</template>
<script>
import SupplierForm from '../../components/Suppliers/SuppliersForm.vue';
import ModuleHelp from '../../components/Help/ModuleHelp.vue';
import {FilterMatchMode,FilterOperator} from 'primevue/api';  
export default {
    data() {
			return {  
                module_name:'Suppliers', 
                actions: [], 
                moduleId:35,
                showHelp : false,
                logo:false,
                isSaving:false, 
				records: null, 
				filters: null, 
				loading: true,  
				selectedRecords: null,
				columns: null,
                selectedColumns: null, 
                showForm:false,
                selectedRecordId: 0,  
                saveAndClose:false, 
			}
		},  
        components:{
            SupplierForm,
            ModuleHelp
        },
		created() { 
            if(this.checkPermission(this.moduleId,'update')){
                this.actions.push({
						label: 'Update',
						icon: 'pi pi-refresh',
                        command: () => {
                            this.getClickedRecord('update');
                        }
					});
            }
            if(this.checkPermission(this.moduleId,'delete')){
                this.actions.push({
						label: 'Delete',
						icon: 'pi pi-times',
                        command: () => {
                            this.getClickedRecord('delete');
                        }
					});
            }   
			this.initFilters();
            this.columns = [
                {field: 'action', header: 'Action'},
                {field: 'company_name', header: 'Company'},  
                {field: 'category_name', header: 'Category'},  
                {field: 'name', header: 'Name'},  
                {field: 'email', header: 'Email'},
                {field: 'registered_company_name', header: 'Registered Company'},
                {field: 'phone', header: 'Phone'},  
                {field: 'address', header: 'Address'},  
                {field: 'country_name', header: 'Country Name'},  
                {field: 'state_name', header: 'State Name'},  
                {field: 'city_name', header: 'City Name'},  
                {field: 'zipcode', header: 'Zipcode'},   
                {field: 'facebook', header: 'Facebook'},   
                {field: 'skype', header: 'Skype'},   
                {field: 'website', header: 'Website'},   
                {field: 'bank_details', header: 'Bank Details'},
                {field: 'description', header: 'Description'},
                {field: 'bank_details', header: 'Bank Details'},
                {field: 'date_added', header: 'Date Added'},
                {field: 'added_by', header: 'Created By'},
                {field: 'date_added', header: 'Date Added'},
                {field: 'modified_by', header: 'Last Modified By'},
                {field: 'date_modification', header: 'Date Modification'},
                {field: 'status', header: 'Status'},
                
            ];
            this.selectedColumns = this.columns;
		},
        computed: { 
        },
		mounted() { 
			this.getRecords();  
            if(this.$route.query.view){
                this.urlParams = JSON.parse(this.$CryptoJS.AES.decrypt(this.$route.query.view, this.encryptKey).toString(this.$CryptoJS.enc.Utf8));
                if(Object.keys(this.urlParams).length){
                    this.urlParams.forEach( (parameter) => {
                        if(parameter.view == 'add-supplier'){ 
                            this.selectedRecordId = parameter.id;
                            this.showForm = true; 
                        }  
                    })
                }
            } 
		},
		methods: {   
            getClickedRecord(action){
                if(action == 'delete'){ 
                    this.deleteRecord(); 
                }
                else if(action == 'view'){
                    this.viewRecord();
                }  
                else if(action == 'update'){
                    this.addViewInURL({view: 'add-supplier', id:this.selectedRecordId});
                    this.showForm = true; 
                }
            },
            toggleRecordMenu(event) {
                this.selectedRecordId = event.currentTarget.getAttribute('recordId');  
				this.$refs.menu.toggle(event);
			},
            loadForm(){ 
                this.selectedRecordId = 0;
                this.addViewInURL({view: 'add-supplier', id:this.selectedRecordId});
                this.showForm = true;
            },
            closeForm(){ 
                this.showForm = false;
                this.removeViewFromURL('add-supplier');
            }, 
            getRecords(){ 
                var Vthis = this;
                this.loading = true;
                this.axios.post('getAllSuppliers')
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){
                        this.records = response.data;  
                        this.loading = false; 
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => { 
                })  
            }, 
            viewRecord(){ 
                var Vthis = this;
                this.loading = true;
                this.axios.post('getAllSuppliers',{id:this.selectedRecordId})
                .then( (response) => {  
                    this.printResponseResult(response,false);   
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => { 
                })  
            },
            deleteRecord(){ 
                var Vthis = this;
                this.$confirm.require({
                    group: 'dialog',
                    header: 'Confirmation',
                    message: 'Are you sure you want to delete?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => { 
                        Vthis.loading = true;  
                        let DeleteAbleRecords = [];
                        if(Vthis.selectedRecords){
                            Vthis.selectedRecordId = '';
                            Vthis.selectedRecords.forEach((data) => {
                                DeleteAbleRecords.push(data.id);
                            })
                        } 
                        else{
                            DeleteAbleRecords.push(Vthis.selectedRecordId);
                        } 
                        
                        // let filterdRecords = Vthis.records.filter(val => Vthis.selectedRecords.includes(val)); 
                        // filterdRecords.forEach((data) => {
                        //     DeleteAbleRecords.push(data.id);
                        // })  

                        Vthis.axios.post('deleteSupplier',{id:DeleteAbleRecords})
                        .then( (response) => {   
                            let responseStatus = this.printResponseResult(response,false); 
                            if(responseStatus == 200){
                                Vthis.getRecords();
                                Vthis.showMessage('Record deleted successfully','success'); 
                            }
                        })
                        .catch((error) => {   
                            this.printResponseResult(error);
                        })
                        .finally(() => { 
                            Vthis.loading = false;
                        })
                    },
                    reject: () => {
                        // this.loading = false;
                    }
                }); 
            },
            exportCSV() {
                this.$refs.dt.exportCSV();
            },
            onToggle(value) {
                this.selectedColumns = this.columns.filter(col => value.includes(col));
            },
            
            initFilters() {
				this.filters = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'category_name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'company_name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'registered_company_name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'facebook': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'skype': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'website': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'phone': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'email': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'bank_details': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'country_name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'state_name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'city_name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'zipcode': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'address': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'added_by': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'modified_by': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
                    'description': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'date_added': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]}, 
					'date_modification': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]}, 
					'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]}, 
				}
			},
			clearFilter() {
				this.initFilters();
			}, 
            isRTL() {
				return this.$appState.RTL;
			}
		},
        watch : { 
        }
}
</script>
 
