<template>  
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="false" @hide="closeForm" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            <form @submit.prevent="login" @keydown="form.onKeydown($event)" enctype="multipart/form-data"> 
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Manage {{module_name}}</h3> 
                        <p>You can add or update {{module_name}} here. </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">  
                        <div class="col-12 lg:col-6 sm:col-12 md:col-6">
                            <div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Name</label>
                                    <InputText id="name" placeholder="Name" v-model="form.name"  type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="name" /> 
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Registered Company Name</label>
                                    <InputText id="registered_company_name" placeholder="Registered Company Name" v-model="form.registered_company_name"  type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="registered_company_name" /> 
                                </div>
                                <div class="col-12 lg:col-6 sm:col-12 mb-2"  style="float:left"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Company</label>
                                    <Dropdown companiesLoading :filter="true" :showClear="true"  v-model="form.company_id" :options="companies" optionLabel="name" optionValue="id"  :placeholder="companiesLoading ? 'Loading...' : 'Select Company'" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="company_id" />
                                </div>
                                <div class="col-12 lg:col-6 sm:col-12 mb-2"  style="float:left"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Supplier Category</label>
                                    <Dropdown categoriesLoading :filter="true" :showClear="true"  v-model="form.category_id" :options="SupplierCategories" optionLabel="name" optionValue="id"  :placeholder="categoriesLoading ? 'Loading...' : 'Select Category'" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="category_id" />
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Email</label>
                                    <InputText id="email" placeholder="Email Sender Name" v-model="form.email"  type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="email" /> 
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Phone</label>
                                    <InputText id="phone" placeholder="Phone" v-model="form.phone" min="0" step="1" type="number" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="phone" /> 
                                </div>  
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Facebook</label>
                                    <InputText id="facebook" placeholder="Facebook" v-model="form.facebook"  type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="facebook" /> 
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Skype</label>
                                    <InputText id="skype" placeholder="skype" v-model="form.skype"  type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="skype" /> 
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Website</label>
                                    <InputText id="website" placeholder="website" v-model="form.website"  type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="website" /> 
                                </div>  
                                
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Country</label>
                                    <Dropdown CountriesLoading @change="getStates($event)" v-model="form.country" :filter="true" :showClear="true" :options="countries" optionLabel="name" optionValue="id" :placeholder="CountriesLoading ? 'Loading...' : 'Select Country'" class="full-width" />
                                    <HasError class="p-error" :form="form" field="country" /> 
                                </div> 
                                <div v-if="form.country > 0" class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>State</label>
                                    <Dropdown StatesLoading @change="getCities($event)" v-model="form.state" :filter="true" :showClear="true" :options="states" optionLabel="name" optionValue="id" :placeholder="StatesLoading ? 'Loading...' : 'Select State'" class="full-width" />
                                    <HasError class="p-error" :form="form" field="city" /> 
                                </div> 
                                <div v-if="form.state > 0" class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>City</label>
                                    <Dropdown CitiesLoading v-model="form.city" :filter="true" :showClear="true" :options="cities" optionLabel="name" optionValue="id" :placeholder="CitiesLoading ? 'Loading...' : 'Select City'" class="full-width" />
                                    <HasError class="p-error" :form="form" field="country" /> 
                                </div> 
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Postal Code/ZipCode</label>
                                    <InputText id="zipcode" placeholder="Zipcode" v-model="form.zipcode" type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="zipcode" /> 
                                </div> 
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Address</label>
                                    <InputText id="address" placeholder="Address" v-model="form.address" type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="address" /> 
                                </div> 
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Status</label>
                                    <Dropdown v-model="form.status" :options="RecordStatuses" optionLabel="name" optionValue="id" placeholder="Select Status" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="status" />
                                </div> 
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Bank Details</label>
                                    <Textarea placeholder="Bank Details" v-model="form.bank_details" rows="5" cols="30" class="full-width" />
                                    <HasError class="p-error" :form="form" field="bank_details" />
                                </div> 
                               <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>About/Description</label>
                                    <Textarea placeholder="Description" v-model="form.description" rows="5" cols="30" class="full-width" />
                                    <HasError class="p-error" :form="form" field="description" />
                                </div>
                                
                            </div>  
                        </div> 
                        <div class="col-12 lg:col-6 sm:col-12 md:col-6">
                            <div> 
                                <Panel v-if="image" class="mb-3" header="Current image" :toggleable="true">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2 profileCardImg"> 
                                        <div>
                                            <img alt="image" :src="image" >
                                        </div>
                                        <div>
                                            <ConfirmPopup group="popup"></ConfirmPopup>
                                            <Button ref="popup" @click="confirmFileDeletion($event)" icon="pi pi-times" label="Remove" class="p-button-danger p-button-raised p-button-md" style="margin-left: .5em" />
                                        </div>   
                                    </div>
                                </Panel>
                                <Panel class="mb-3" :header="module_name+' image'" :toggleable="true">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                        <FileUpload name="image" :showUploadButton="false" :customUpload="true" @select="myUploader" :multiple="false" accept="image/*" :maxFileSize="1000000"> </FileUpload> 
                                    </div> 
                                </Panel>  
                                <Panel class="mb-3" header="Vehicle Types" :toggleable="true">  
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2" v-for="(type,key) in form.vehicleTypes" :key="key"> 
                                       <div class="p-inputgroup">
                                            <span class="p-inputgroup-addon">
                                                {{type.name}}
                                            </span>
                                            <InputNumber v-model="form.vehicleTypes[key].quantity" placeholder="Number of Vehicles" class="full-width" />
                                            <HasError class="p-error" :form="form" field="vehicleTypes" /> 
                                        </div> 
                                    </div> 
                                </Panel>  
                            </div>   
                        </div>
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Save" :loading="isSaving" icon="pi pi-check" @click="save()" class="p-button-raised p-button-lg p-button-success mr-2"/>
                                <Button type="submit" label="Save and Close" :loading="isSaving" icon="pi pi-check" @click="saveAndClose=true, save()" class="p-button-raised p-button-lg p-button-info mr-2"/>
                                <Button type="button" label="Reset" icon="pi pi-refresh" class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="resetForm()"/>
                            </template>
                            <template v-slot:end> 
                                <Button type="button" label="Cancel" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm"/>
                            </template> 
                        </Toolbar>   
                    </div>
                </div> 
            </form>
        </Sidebar> 
    </BlockUI>
    
</template>
<script> 
export default {
    data() {
			return {  
                form: new this.Form({ 
                    id: '', 
                    name: '',   
                    facebook:'', 
                    website:'', 
                    skype:'', 
                    registered_company_name:'', 
                    category_id:0, 
                    company_id:0, 
                    email:'',  
                    phone:'', 
                    country:0,
                    state:0,
                    city:0,
                    zipcode:'', 
                    address: '', 
                    image: '', 
                    status:1, 
                    description: '',  
                    bank_details: '',  
                    vehicleTypes:[],      
                }), 
                image:'', 
                vehicleTypesLoading:false,
                companiesLoading:false, 
                categoriesLoading:false, 
                CitiesLoading:false,
                StatesLoading:false,
                CountriesLoading:false,
                module_name:'Supplier',  
                isSaving:false,  
                saveAndClose:false, 
                recId:0,
                loadingComponent:true,
                formVisible:false,
                vehicle_types:[],
                countries:[],
                states:[],
                cities:[],
                companies:[],
                SupplierCategories: [],  
			}
		},   
        props:{
            showForm : {
                type : Boolean,
                required : true,
            },
            recordId :{
                type : Number,
            },
        },
		created() {   
		},
        computed: { 
        },
		mounted() {  
            this.resetForm();
            this.formVisible = this.showForm; 
            this.recId = this.recordId;   
            this.getAllRequestData();  
		},
		methods: {   
             getAllRequestData(){   
                this.$axios.all([this.getSupplierCategoris()])
                .then(() => {  
                    return this.getCompanies();    
                })  
                .then(() => {  
                    return this.updateRecord();    
                }) 
                .then(() => {  
                    return this.getVehicleTypes();    
                })   
                .then(() => {  
                    return this.getCountries();     
                })  
                .then(() => {  
                    return this.getStates();    
                }) 
                .then(() => {  
                    return this.getCities();  
                })  
                .then(() => { 
                    this.blockComponent = false;     
                }).catch(() => {    
                //    this.getAllRequestData();
                })
                .finally(() => {  
                }); 
            },

            async getSupplierCategoris(){  
                this.categoriesLoading = true; 
                var Vthis = this;  
                await Vthis.axios.post('getAllSupplierCategories')
                .then( (response) => {   
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.SupplierCategories = response.data;   
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => {  
                    this.categoriesLoading = false; 
                }) 
                 
            },
            async getVehicleTypes(){  
                this.vehicleTypesLoading = true; 
                var Vthis = this;  
                await Vthis.axios.post('getAllVehicleTypes')
                .then( (response) => {
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){     
                        var selectedVehicleTypes = Vthis.form.vehicleTypes;
                        Vthis.form.vehicleTypes = new Array(); 
                        response.data.forEach( (type) => {  
                            var obj = {
                                'id' : type.id,
                                'name' : type.name,
                                'quantity' : 0,
                            };
                            selectedVehicleTypes.forEach( (rec) => {
                                if(rec.vehicle_type_id == type.id){
                                    obj.quantity = rec.quantity;
                                }
                            })  
                            Vthis.form.vehicleTypes.push(obj);
                        });  
                        this.vehicle_types = response.data;   
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => {  
                    this.vehicleTypesLoading = false; 
                }) 
                 
            },
            async getCompanies(){  
                this.companiesLoading = true; 
                var Vthis = this;  
                await Vthis.axios.post('getAllCompanies')
                .then( (response) => {   
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.companies = response.data;   
                        if(this.form.company_id==0){
                            this.form.company_id = response.data[0].id;
                        }
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => {  
                    this.companiesLoading = false; 
                }) 
                 
            },
             
             async getCountries(){  
                this.CountriesLoading = true; 
                var Vthis = this;  
                await Vthis.axios.post('getAllCountries')
                .then( (response) => {   
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.countries = response.data;   
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => {  
                    this.CountriesLoading = false; 
                }) 
                 
            },

            async getStates($event=''){  
               
                var country = 0;
                if(this.recId){ 
                    country = this.form.country;
                }
                else if($event.value){
                     country = $event.value;
                }
                
                if(country){ 
                    this.StatesLoading = true;
                    var Vthis = this;  
                    await Vthis.axios.post('getAllStates',{country:country})
                    .then( (response) => {   
                        let responseStatus = this.printResponseResult(response,false); 
                        if(responseStatus == 200){ 
                            this.states = response.data;   
                        }
                    })
                    .catch((error) => {    
                        this.printResponseResult(error);
                    })
                    .finally(() => {  
                        this.StatesLoading = false;
                    }) 
                }
            },

            async getCities($event=''){  

                var state = 0;
                if(this.recId){ 
                    state = this.form.state;
                }
                else if($event.value){
                     state = $event.value;
                }
                if(state){ 
                    this.CitiesLoading = true;
                    var Vthis = this;  
                    await Vthis.axios.post('getAllCities',{state:state})
                    .then( (response) => {  
                        let responseStatus = this.printResponseResult(response,false); 
                        if(responseStatus == 200){  
                            this.cities = response.data;   
                        }
                    })
                    .catch((error) => {    
                        this.printResponseResult(error);
                    })
                    .finally(() => {  
                        this.CitiesLoading = false;
                    }) 
                }
            },

            resetForm(){
                this.form.reset();
            },
            async save(){   
                var Vthis = this; 
                this.isSaving = true;
                await this.form
                .post(Vthis.$baseurl+'api/saveSupplier', Vthis.form)
                .then((response) => { 
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        // this.getRecords();
                        if(this.saveAndClose){   
                            this.saveAndClose = false;
                            setTimeout( () => {this.closeForm();},1000);
                        }   
                        Vthis.showMessage('Saved successfully','success',); 
                    }
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isSaving = false; 
                }) 
            }, 
            loadForm(){
                this.resetForm(); 
            },
            closeForm(){
                this.$emit('formClose',true); 
            },
            myUploader(event){   
                this.form.image = event.files;
            },  
            async updateRecord(){ 
                if(this.recId > 0){
                    var Vthis = this;
                    this.loading = true;
                    await this.axios.post('getAllSuppliers',{id:this.recId})
                    .then( (response) => {    
                        let responseStatus = this.printResponseResult(response,false); 
                        if(responseStatus == 200){ 
                            response.data[0].status = response.data[0].status == 'Blocked' ? 0 : 1;
                            if(response.data[0].vehicleTypes === undefined){
                                response.data[0].vehicleTypes = [];
                            }
                            Vthis.form.fill(response.data[0]); 
                            Vthis.image = false;
                            if(response.data[0].image){
                                Vthis.image = Vthis.filesUrl+"suppliers/"+response.data[0].image;
                            }  

                            // if(response.data[0].company_currency_ids){
                            //     var selectedCurrencies = response.data[0].company_currency_ids.split(','); 
                            //     if(selectedCurrencies.length > 0){ 
                            //         var frr = [];
                            //         this.Currencies.forEach( (currency) => {  
                            //             if(selectedCurrencies.includes(currency.id.toString())){ 
                            //                 frr.push(currency.id);
                            //             } 
                            //         });
                            //         Vthis.form.currency = frr;
                            //     }
                            // } 
                        }


                    })
                    .catch((error) => {    
                        this.printResponseResult(error);
                    })
                    .finally(() => { 
                        Vthis.loading = false;
                    })  
                }
            },   
            async removeimage(){ 
                var Vthis = this;  
                await Vthis.axios.post('deleteSupplierImage',{id:this.form.id})
                .then( (response) => {   
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        Vthis.image = false;
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => {   
                }) 
            },
            confirmFileDeletion($event){
                this.$confirm.require({
                    target: $event.currentTarget,
                    message: 'Are you sure you want to proceed?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        this.removeimage();
                    },
                    reject: () => {
                        
                    }
                });
            }
		},
        watch : {
             
        }
}
</script>
 
